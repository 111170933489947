<template>
  <div class="page">
    <div class="content">
      <div class="articles">
      <ul class="list works">
        <li v-for="(item,xh) in dataList" :key="xh" class="li">
          <a :href="item.url" target="_blank">
            <div class="title">{{item.title}}</div>
            <div class="time">{{item.time}}</div>
            <div class="img" v-if="item.tpic"><img :src="item.tpic"></div>
            <div class="img" v-else><img src="@/assets/myimg/logo.png" style="opacity: .2"></div>
          </a>
        </li>
      </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'articles',
    data() {
        return {

        }
    },
     props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {

    },
    methods: {},
    computed: {}
}
</script>

